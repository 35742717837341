
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { Download, Folder, useDownloadStore } from '@/stores/download';
import { escapeRegExp } from '@/utils/string';
import { getTranslated } from '@/translation';
import { Tree } from '@/utils/object';

export default defineComponent({
  name: 'bp-download-menu-item',
  props: {
    data: {
      type: Array as PropType<Tree<Download>[]>,
      default: () => [],
    },
    filter: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:filter'
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// FOLDERS
    ///-------------------------------------------------------------------

    const store = useDownloadStore();
    const folders = computed(() => props.data.filter(dl => dl.doc_type === 'download_folder') as Tree<Folder>[])
    const open = ref<Set<string>>();

    watch(() => folders.value, folders => {
      if (!open.value && folders.length > 0) {
        open.value = new Set(folders.filter(el => store.hasChildren(el._id, 'folder')).map(el => el._id));
      }
    }, { immediate: true });

    function toggle(folderId: string) {
      if (open.value) {
        if (open.value.has(folderId)) {
          open.value.delete(folderId);
        } else {
          open.value.add(folderId);
        }
      }
    }

    function folderIcon(folderId: string) {
      if (open.value && open.value.has(folderId)) {
        return ['far', 'folder-open'];
      }
      return ['far', 'folder'];
    }

    ///-------------------------------------------------------------------
    /// FILTER
    ///-------------------------------------------------------------------

    function updateFilter(folderId: string) {
      ctx.emit('update:filter', folderId);
    }

    ///-------------------------------------------------------------------
    /// FILES
    ///-------------------------------------------------------------------

    function fileCount(folder: Folder) {
      const regex = new RegExp(escapeRegExp(props.search.trim()), 'i');
      const files = store.getFilesOfSubtree(folder._id).filter(file => {
        const filename = Object.keys(file._attachments).join()
        const path = store.getPathById(file._id).map(dl => getTranslated(dl.name)).join(',');
        return regex.test(filename) || regex.test(path)
      });
      return files.length || '';
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      fileCount,
      folderIcon,
      folders,
      getTranslated,
      open,
      store,
      toggle,
      updateFilter,
    }
  }
});
