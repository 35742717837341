import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "bp-download-area-view" }
const _hoisted_2 = { class: "bp-download-area-view__search-wrapper" }
const _hoisted_3 = { class: "bp-download-area-view__notification" }
const _hoisted_4 = {
  key: 0,
  class: "bp-download-area-view__view-toggle"
}
const _hoisted_5 = { class: "flex gap-sm" }
const _hoisted_6 = { class: "bp-download-area-view__menu-item-count" }
const _hoisted_7 = {
  key: 0,
  style: {"grid-column":"1 / -1"}
}
const _hoisted_8 = {
  key: 1,
  style: {"grid-column":"1 / -1"}
}
const _hoisted_9 = { class: "bp-download-area-view__file-header" }
const _hoisted_10 = { class: "bp-download-area-view__file-path" }
const _hoisted_11 = { class: "bp-download-area-view__file-name-wrapper" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 2,
  class: "bp-download-area-view__file-details"
}
const _hoisted_15 = {
  key: 3,
  class: "bp-download-area-view__file-details"
}
const _hoisted_16 = {
  key: 4,
  class: "bp-download-area-view__file-details"
}
const _hoisted_17 = {
  key: 5,
  class: "bp-download-area-view__compatibilities-wrapper",
  style: {"margin-right":"auto"}
}
const _hoisted_18 = {
  key: 0,
  style: {"font-size":"smaller","opacity":"0.67"}
}
const _hoisted_19 = { class: "bp-download-area-view__file-download-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_toggle_button = _resolveComponent("bp-toggle-button")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_download_menu_item = _resolveComponent("bp-download-menu-item")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_bp_tooltip = _resolveComponent("bp-tooltip")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      image: "/static/background/app_background_download.jpg",
      icon: ['fad','download']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Download area")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      "fill-width": "",
      "fill-height": "",
      loading: !_ctx.store.loaded || !_ctx.operatingSystemStore.loaded,
      tabs: [{_id: 'downloads'}],
      "tabs-position": "inside"
    }, {
      "tabs-header": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Receive notifications about new downloads?")
              ])),
              _: 1
            }),
            _createVNode(_component_bp_button, {
              icon: "gears",
              to: {name: 'datamanagement', query: {tab: 'email_notifications'}}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Configure")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_bp_input, {
            class: "bp-download-area-view__search",
            clearable: "",
            large: _ctx.isLargeDesktop,
            placeholder: _ctx.$gettext('Search…'),
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
          }, null, 8, ["large", "placeholder", "modelValue"]),
          (_ctx.isTablet)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_translate, { class: "bp-download-area-view__view-toggle-heading" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Toggle view")
                  ])),
                  _: 1
                }),
                _createVNode(_component_bp_toggle_button, {
                  data: [
              // { _id: 'list', icon: 'list', text: $gettext('List layout'), click: () => masonry = false },
              { _id: 'grid', icon: 'grid-2', text: _ctx.$gettext('Grid layout'), click: () => _ctx.masonry = false },
              { _id: 'masonry', icon: 'objects-column', text: _ctx.$gettext('Masonry layout'), click: () => _ctx.masonry = true },
            ]
                }, null, 8, ["data"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      tabs: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['bp-download-area-view__menu-item', {
          'bp-download-area-view__menu-item--highlighted': _ctx.filter === 'all',
        }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateFilter('all')))
        }, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_bp_icon, {
              class: "pd-md",
              icon: ['far', 'folders']
            }),
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("All")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.allFilesCount), 1)
        ], 2),
        _createVNode(_component_bp_download_menu_item, {
          data: _ctx.store.getTree(),
          search: _ctx.search,
          filter: _ctx.filter,
          "onUpdate:filter": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter) = $event))
        }, null, 8, ["data", "search", "filter"])
      ]),
      "tabs-content": _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.masonry ? 'bp-masonry-grid' : 'div'), {
          class: "bp-download-area-view__files",
          ref: "contentEl"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: file._id
              }, [
                ((_ctx.isFeatured(file) || _ctx.files.findIndex(f => !_ctx.isFeatured(f)) === index && !!_ctx.files.find(f => _ctx.isFeatured(f))) && index > 0)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
                  : _createCommentVNode("", true),
                (_ctx.isFeatured(file))
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_8, _toDisplayString(_ctx.store.getFeaturedName(file)), 1))
                  : (_ctx.files.findIndex(f => !_ctx.isFeatured(f)) === index && !!_ctx.files.find(f => _ctx.isFeatured(f)))
                    ? (_openBlock(), _createBlock(_component_translate, {
                        key: 2,
                        style: {"grid-column":"1 / -1"},
                        tag: "h3"
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode("More downloads")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                _createVNode(_component_bp_card, {
                  class: _normalizeClass(['bp-download-area-view__file', {
                'bp-download-area-view__file--featured': _ctx.isFeatured(file),
              }]),
                  "fill-width": "",
                  ribbon: _ctx.isFeatured(file) ? _ctx.$gettext('NEW') : undefined,
                  "ribbon-color": _ctx.isFeatured(file) ? 'yellow' : undefined,
                  "ribbon-icon": _ctx.isFeatured(file) ? 'star' : undefined,
                  "data-file-id": file._id
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getPathById(file._id), (folder, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: _ctx.pathKey(folder._id)
                          }, [
                            (index > 0)
                              ? (_openBlock(), _createBlock(_component_bp_icon, {
                                  key: 0,
                                  icon: "angle-right"
                                }))
                              : _createCommentVNode("", true),
                            _withDirectives((_openBlock(), _createElementBlock("span", null, [
                              _createTextVNode(_toDisplayString(_ctx.getTranslated(folder.name)), 1)
                            ])), [
                              [_directive_tooltip, _ctx.pathTooltip]
                            ])
                          ], 64))
                        }), 128))
                      ]),
                      (_ctx.getTranslated(file.hint) || file.show_checksum)
                        ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                            class: "bp-download-area-view__file-hint-icon",
                            icon: ['fad', 'info-circle'],
                            key: _ctx.currentLanguageISOString()
                          })), [
                            [_directive_tooltip, { interactive: true, offset: [-16, 4], placement: 'top-end', text: _ctx.getHintText(file) }]
                          ])
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  footer: _withCtx(() => [
                    _createElementVNode("span", _hoisted_19, [
                      _createVNode(_component_bp_tooltip, {
                        condition: file._disabled,
                        tooltip: _ctx.$gettext(`You don't have a valid license to download this file`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_button, {
                            color: "green",
                            download: "",
                            onClick: ($event: any) => (_ctx.store.download(file)),
                            disabled: file._disabled
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_translate, null, {
                                default: _withCtx(() => _cache[22] || (_cache[22] = [
                                  _createTextVNode("Download")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1032, ["onClick", "disabled"])
                        ]),
                        _: 2
                      }, 1032, ["condition", "tooltip"])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(['bp-download-area-view__file-content', { 'bp-download-area-view__file-content--has-description': _ctx.getTranslated(file.description) }])
                    }, [
                      _createElementVNode("span", _hoisted_11, [
                        _createVNode(_component_bp_icon, {
                          class: "bp-download-area-view__file-icon",
                          icon: _ctx.store.getIcon(file)
                        }, null, 8, ["icon"]),
                        _createElementVNode("strong", null, _toDisplayString(Object.keys(file._attachments).join()), 1)
                      ]),
                      (_ctx.getTranslated(file.description))
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            class: _normalizeClass(['bp-download-area-view__file-description', {
                  'bp-download-area-view__file-description--expanded': _ctx.descriptionExpanded.has(file._id),
                }]),
                            innerHTML: _ctx.getTranslated(file.description)
                          }, null, 10, _hoisted_12))
                        : _createCommentVNode("", true),
                      (_ctx.descriptionExpandible.get(file._id))
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: "bp-download-area-view__file-description-read-more",
                            onClick: ($event: any) => (_ctx.toggleDescription(file._id))
                          }, [
                            (_ctx.descriptionExpanded.has(file._id))
                              ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                    _createTextVNode("Show less")
                                  ])),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                                    _createTextVNode("Show more")
                                  ])),
                                  _: 1
                                })),
                            _createVNode(_component_bp_icon, {
                              class: "ml-sm",
                              icon: _ctx.descriptionExpanded.has(file._id) ? 'arrow-up' : 'arrow-down'
                            }, null, 8, ["icon"])
                          ], 8, _hoisted_13))
                        : _createCommentVNode("", true),
                      (_ctx.isFeatured(file) && _ctx.isLargeDesktop)
                        ? (_openBlock(), _createElementBlock("table", _hoisted_14, [
                            _createElementVNode("tr", null, [
                              _createVNode(_component_translate, { tag: "th" }, {
                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                  _createTextVNode("Version")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_translate, { tag: "th" }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode("Date")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_translate, { tag: "th" }, {
                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                  _createTextVNode("File size")
                                ])),
                                _: 1
                              })
                            ]),
                            _createElementVNode("tr", null, [
                              _createElementVNode("td", null, [
                                _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                                  to: { name: 'changelog', query: {
                      tab: _ctx.versionStore.getUUIDPathById(file.version).join('_'),
                      version: _ctx.versionStore.getVersionById(file.version)
                    }}
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString([
                        ...Array.from(new Set(_ctx.versionStore.getPathById(file.version)
                          .filter(version => version.doc_type === 'version_component')
                          .map(component => _ctx.getTranslated(component.name))
                        )),
                        _ctx.versionStore.getVersionById(file.version)
                      ].join(' ')), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"])), [
                                  [_directive_tooltip, _ctx.$gettext('Show changelog')]
                                ])
                              ]),
                              _createElementVNode("td", null, _toDisplayString(_ctx.localizeDate(file.date)), 1),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formatBytes(Object.values(file._attachments)[0].length)), 1)
                            ])
                          ]))
                        : (_ctx.isFeatured(file) && _ctx.isDesktop)
                          ? (_openBlock(), _createElementBlock("table", _hoisted_15, [
                              _createElementVNode("tr", null, [
                                _createVNode(_component_translate, { tag: "th" }, {
                                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                                    _createTextVNode("Version")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _createElementVNode("tr", null, [
                                _createElementVNode("td", null, [
                                  _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                                    to: { name: 'changelog', query: {
                      tab: _ctx.versionStore.getUUIDPathById(file.version).join('_'),
                      version: _ctx.versionStore.getVersionById(file.version)
                    }}
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString([
                        ...Array.from(new Set(_ctx.versionStore.getPathById(file.version)
                          .filter(version => version.doc_type === 'version_component')
                          .map(component => _ctx.getTranslated(component.name))
                        )),
                        _ctx.versionStore.getVersionById(file.version)
                      ].join(' ')), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"])), [
                                    [_directive_tooltip, _ctx.$gettext('Show changelog')]
                                  ])
                                ])
                              ]),
                              _createElementVNode("tr", null, [
                                _createVNode(_component_translate, {
                                  tag: "th",
                                  style: {"padding-top":"var(--padding-sm)"}
                                }, {
                                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                                    _createTextVNode("Date")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _createElementVNode("tr", null, [
                                _createElementVNode("td", null, _toDisplayString(_ctx.localizeDate(file.date)), 1)
                              ]),
                              _createElementVNode("tr", null, [
                                _createVNode(_component_translate, {
                                  tag: "th",
                                  style: {"padding-top":"var(--padding-sm)"}
                                }, {
                                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                                    _createTextVNode("File size")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _createElementVNode("tr", null, [
                                _createElementVNode("td", null, _toDisplayString(_ctx.formatBytes(Object.values(file._attachments)[0].length)), 1)
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("table", _hoisted_16, [
                              _createElementVNode("tr", null, [
                                _createVNode(_component_translate, { tag: "th" }, {
                                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                                    _createTextVNode("Version")
                                  ])),
                                  _: 1
                                }),
                                _createElementVNode("td", null, [
                                  _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                                    to: { name: 'changelog', query: {
                      tab: _ctx.versionStore.getUUIDPathById(file.version).join('_'),
                      version: _ctx.versionStore.getVersionById(file.version)
                    }}
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString([
                        ...Array.from(new Set(_ctx.versionStore.getPathById(file.version)
                          .filter(version => version.doc_type === 'version_component')
                          .map(component => _ctx.getTranslated(component.name))
                        )),
                        _ctx.versionStore.getVersionById(file.version)
                      ].join(' ')), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"])), [
                                    [_directive_tooltip, _ctx.$gettext('Show changelog')]
                                  ])
                                ])
                              ]),
                              _createElementVNode("tr", null, [
                                _createVNode(_component_translate, { tag: "th" }, {
                                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                                    _createTextVNode("Date")
                                  ])),
                                  _: 1
                                }),
                                _createElementVNode("td", null, _toDisplayString(_ctx.localizeDate(file.date)), 1)
                              ]),
                              _createElementVNode("tr", null, [
                                _createVNode(_component_translate, { tag: "th" }, {
                                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                                    _createTextVNode("File size")
                                  ])),
                                  _: 1
                                }),
                                _createElementVNode("td", null, _toDisplayString(_ctx.formatBytes(Object.values(file._attachments)[0].length)), 1)
                              ])
                            ])),
                      (file.compatibilities !== null && _ctx.store.getCompatibilities(file).length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.operatingSystemStore.getGroupedVersions(_ctx.store.getCompatibilities(file))), ([designationId, versions]) => {
                              return _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: designationId + _ctx.darkMode,
                                class: "bp-download-area-view__shield"
                              }, [
                                _createElementVNode("div", {
                                  style: _normalizeStyle(_ctx.shieldStyle('designation', designationId))
                                }, _toDisplayString(_ctx.operatingSystemStore.getName(_ctx.operatingSystemStore.getById(designationId))), 5),
                                _createElementVNode("div", {
                                  style: _normalizeStyle(_ctx.shieldStyle('version', designationId))
                                }, [
                                  _createTextVNode(_toDisplayString(versions[0].name), 1),
                                  (versions.length > 1)
                                    ? (_openBlock(), _createElementBlock("em", _hoisted_18, [
                                        _cache[21] || (_cache[21] = _createTextVNode(" ")),
                                        _createVNode(_component_translate, null, {
                                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                                            _createTextVNode("& older")
                                          ])),
                                          _: 1
                                        })
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 4)
                              ])), [
                                [_directive_tooltip, { interactive: true, text: `<p>${_ctx.$gettext('Compatible with:')}</p><ul><li>${versions.map(version => _ctx.operatingSystemStore.getName(version)).join('</li><li>')}</li></ul>` }]
                              ])
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ], 2)
                  ]),
                  _: 2
                }, 1032, ["class", "ribbon", "ribbon-color", "ribbon-icon", "data-file-id"])
              ], 64))
            }), 128))
          ]),
          _: 1
        }, 512))
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}