
import { $gettext } from 'vue-gettext';
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { computed, CSSProperties, defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { currentLanguageISOString, getTranslated } from '@/translation';
import { escapeRegExp, formatBytes, base64ToHex } from '@/utils/string';
import { File, useDownloadStore } from '@/stores/download';
import { fileIcon } from '@/components/icon/BpIcon';
import { localizeDate } from '@/utils/date';
import { useVersionStore } from '@/stores/version';
import BpDownloadMenuItemVue from '@/components/download/BpDownloadMenuItem.vue';
import { Designation, useOperatingSystemStore } from '@/stores/operatingSystem';
import { textColor } from '@/utils/color';
import getCssVariable from '@/utils/css';
import useDarkMode from '@/compositions/use-dark-mode';

export default defineComponent({
  name: 'download-area',
  components: {
    BpDownloadMenuItem: BpDownloadMenuItemVue,
  },
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useDownloadStore();
    const versionStore = useVersionStore();

    ///-------------------------------------------------------------------
    /// FILTER
    ///-------------------------------------------------------------------

    const filter = ref('all');

    function updateFilter(folderId: string) {
      filter.value = folderId;
    }

    const contentEl = ref<HTMLDivElement>();

    watch(() => filter.value, scrollToContent);

    function scrollToContent() {
      const layout = document.getElementById('bp-layout');
      if (!layout) {
        return;
      }
      window.setTimeout(() => {
        if (!contentEl.value) {
          return;
        }
        const headerHeight = parseFloat(window.getComputedStyle(layout).getPropertyValue('--portal-header-height'));
        const { top } = contentEl.value.getBoundingClientRect();
        window.scrollTo({ left: 0, top: window.scrollY + top - headerHeight, behavior: 'smooth' })
      }, 0)
    }

    ///-------------------------------------------------------------------
    /// SEARCH
    ///-------------------------------------------------------------------

    const search = ref('');

    ///-------------------------------------------------------------------
    /// GRID
    ///-------------------------------------------------------------------

    const masonry = ref(false);

    ///-------------------------------------------------------------------
    /// DOWNLOADS
    ///-------------------------------------------------------------------

    const files = computed(() => {
      if (!store.loaded || !operatingSystemStore.loaded || operatingSystemStore.isLoading()) {
        return [];
      }
      const regex = new RegExp(escapeRegExp(search.value.trim()), 'i');
      return (
        filter.value === 'all'
        ? store.getFiles(['featured', 'non-featured'])
        : store.getFilesOfSubtree(filter.value, ['featured', 'non-featured'])
      ).filter(file => {
        const filename = Object.keys(file._attachments).join();
        const path = store.getPathById(file._id).map(dl => getTranslated(dl.name)).join(',');
        return regex.test(filename) || regex.test(path)
      })
    });

    const allFilesCount = computed(() => {
      const regex = new RegExp(escapeRegExp(search.value.trim()), 'i');
      return store.getFiles(['featured', 'non-featured']).filter(file => {
        const filename = Object.keys(file._attachments).join();
        const path = store.getPathById(file._id).map(dl => getTranslated(dl.name)).join(',');
        return regex.test(filename) || regex.test(path)
      }).length || ''
    });

    const featuredIds = ref<Set<string>>(new Set());

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      featuredIds.value = new Set(store.getFiles('featured').map(file => file._id));
    }, { immediate: true });

    function isFeatured(file: File) {
      return featuredIds.value.has(file._id);
    }

    ///-------------------------------------------------------------------
    /// DOM
    ///-------------------------------------------------------------------

    const fileElIds = ref<string[]>([]);
    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      nextTick(() => {
        const ids: string[] = [];
        document.querySelectorAll('[data-file-id]').forEach(el => ids.push(el.getAttribute('data-file-id') as string));
        fileElIds.value = ids;
      })
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// DESCRIPTION
    ///-------------------------------------------------------------------

    const descriptionExpanded = ref(new Set<string>());

    const descriptionExpandible = ref(new Map<string, boolean>());
    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      descriptionExpandible.value = new Map(fileElIds.value.map(id => [id, doesDescriptionWrapFor(id)]))
    });

    function doesDescriptionWrapFor(id: string) {
      const cardEl = document.querySelector(`[data-file-id="${id}"]`) as HTMLElement;
      if (!cardEl) {
        return false;
      }
      const descriptionEl = cardEl.querySelector('.bp-download-area-view__file-description') as HTMLElement;
      if (!descriptionEl) {
        return false;
      }
      return descriptionEl.scrollHeight > Math.ceil(descriptionEl.getBoundingClientRect().height);
    }

    function updateDescriptionExpanded() {
      fileElIds.value.forEach(id => descriptionExpandible.value.set(id, doesDescriptionWrapFor(id)));
    }
    watch(() => fileElIds.value, updateDescriptionExpanded, { immediate: true });
    onMounted(() => {
      addEventListener('resize', updateDescriptionExpanded, { passive: true });
    })
    onUnmounted(() => {
      removeEventListener('resize', updateDescriptionExpanded);
    })

    function toggleDescription(id: string) {
      if (descriptionExpanded.value.has(id)) {
        descriptionExpanded.value.delete(id);
      } else {
        descriptionExpanded.value.add(id);
      }
    }

    ///-------------------------------------------------------------------
    /// HINT
    ///-------------------------------------------------------------------

    function getHintText(file: File) {
      const folderHints = store.getPathById(file._id).map(folder => getTranslated(folder.hint));
      const hint = getTranslated(file.hint);
      let text = [...folderHints, hint].join('')
      if (text.length > 0 && file.show_checksum) {
        text += '<hr style="margin: 0;">';
      }
      if (file.show_checksum) {
        text += `<p><strong>${$gettext('Checksum')}:</strong> <span class="mono">${base64ToHex(store.getAttachment(file).digest)}</span></p>`;
      }
      return text;
    }

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------

    const isTablet = useMedia(Breakpoint.MIN_MD);
    const isDesktop = useMedia(Breakpoint.MIN_MD);
    const isLargeDesktop = useMedia(Breakpoint.MIN_LG);

    ///-------------------------------------------------------------------
    /// TOOLTIP
    ///-------------------------------------------------------------------

    function pathTooltip(el: HTMLSpanElement) {
      return { text: el.scrollWidth > el.clientWidth ? el.innerText : '' };
    }

    function pathKey(folderId: string) {
      return `${folderId}_${window.innerWidth}`
    }

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------
    
    const { current: darkMode } = useDarkMode();

    ///-------------------------------------------------------------------
    /// COMPATIBILITIES
    ///-------------------------------------------------------------------
    
    const operatingSystemStore = useOperatingSystemStore();
  
    function shieldStyle(type: 'designation' | 'version', designationId: string): CSSProperties {
      const designation = operatingSystemStore.getById(designationId) as Designation;
      return {
        backgroundColor: type === 'version' && designation ? designation?.color : 'var(--theme-text-secondary)',
        color: textColor(type === 'version' && designation ? designation?.color : getCssVariable('theme-text-secondary')),
        padding: 'var(--padding-xs) calc(1.5 * var(--padding-xs))',
      }
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      allFilesCount,
      base64ToHex,
      contentEl,
      currentLanguageISOString,
      darkMode,
      descriptionExpanded,
      descriptionExpandible,
      fileIcon,
      files,
      filter,
      formatBytes,
      getCssVariable,
      getHintText,
      getTranslated,
      isDesktop,
      isFeatured,
      isLargeDesktop,
      isTablet,
      localizeDate,
      masonry,
      operatingSystemStore,
      pathKey,
      pathTooltip,
      search,
      shieldStyle,
      store,
      textColor,
      toggleDescription,
      updateFilter,
      versionStore,
    }
  }
});
